import { createRouter, createWebHistory } from 'vue-router';
// import { getCookie } from '@/utils/cookie';
// 业务页面路由
const pageRoutes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/view/h5/Index.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home/',
        name: 'pc_index',
        component: () => import('@/view/home/Index.vue'),
        redirect: '/home/index',
        children: [
          {
            path: 'index',
            name: 'Index',
            component: () => import('@/view/home/index/Main.vue')
          },
          {
            path: 'person',
            name: 'Person',
            component: () => import('@/view/home/person/Index.vue'),
            redirect: '/home/person/user',
            children: [
              {
                path: 'user',
                name: 'User',
                component: () => import('@/view/home/person/User.vue')
              },
              {
                path: 'about',
                name: 'About',
                component: () => import('@/view/home/person/About.vue')
              },
              {
                path: 'about/privacy',
                name: 'Privacy',
                component: () => import('@/view/home/person/Privacy.vue')
              } //隐私协议
            ]
          },
          {
            path: 'advice',
            name: 'Advice',
            component: () => import('@/view/home/advice/Index.vue')
          },
          {
            path: 'chat',
            name: 'Chat',
            component: () => import('@/view/home/advice/Index.vue')
          }, //chat
          {
            path: 'place',
            name: 'Place',
            component: () => import('@/view/home/place/Index.vue')
          } //场景专区
        ]
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/view/login/Index.vue')
      }, //登录
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/view/register/Index.vue')
      } //注册
    ]
  },
  // 404路由
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/'
  }
];
const h5Routes = [
  {
    path: '/h5/',
    name: 'h5_index',
    component: () => import('@/view/h5/Index.vue'),
    redirect: '/h5/home',
    children: [
      {
        path: 'Home',
        name: 'h5_Home',
        component: () => import('@/view/h5/home/Index.vue')
      }, //首页
      {
        path: 'login',
        name: 'h5_Login',
        component: () => import('@/view/h5/login/Index.vue')
      }, //登录
      {
        path: 'register',
        name: 'h5_Register',
        component: () => import('@/view/h5/register/Index.vue')
      }, //注册
      {
        path: 'aiguid',
        name: 'h5_Aiguid',
        component: () => import('@/view/h5/aiguid/Index.vue')
      }, //咨询
      {
        path: 'place',
        name: 'h5_Place',
        component: () => import('@/view/h5/place/Index.vue')
      }, //场景专区
      {
        path: 'chat',
        name: 'h5_Chat',
        component: () => import('@/view/h5/aiguid/Index.vue')
      }, //chat
      {
        path: 'mine',
        name: 'h5_Mine',
        component: () => import('@/view/h5/mine/Index.vue')
      }, //我的
      {
        path: 'mineInfo',
        name: 'h5_MineInfo',
        component: () => import('@/view/h5/mineInfo/Index.vue')
      }, //个人资料
      {
        path: 'aboutUs',
        name: 'h5_AboutUs',
        component: () => import('@/view/h5/aboutUs/Index.vue')
      }, //个人资料
      {
        path: 'contactUs',
        name: 'h5_ContactUs',
        component: () => import('@/view/h5/aboutUs/contactUs.vue')
      }, //联系我们
      {
        path: 'privacy',
        name: 'h5_Privacy',
        component: () => import('@/view/h5/aboutUs/privacy.vue')
      } //隐私协议
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes: [...pageRoutes, ...h5Routes] as any
});

function isMobileDevice() {
  return /Mobi|Android/i.test(navigator.userAgent);
}
router.beforeEach((to, _from, next) => {
  if (to.path.includes('h5') && !isMobileDevice()) {
    next(false);
    router.push('/home/index');
  } else if (!to.path.includes('h5') && isMobileDevice()) {
    next(false);
    router.push('/h5/home');
  } else {
    next();
  }
});
// router.beforeEach((to, _from, next) => {
//   const token = getCookie('umoai_token') || '';
//   if (!token && to.path !== '/login') {
//     next(false);
//     router.push('/login');
//   } else {
//     next();
//   }
// }); //产品不要了
export default router;
