import './assets/base.css';
import './assets/common.css';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createPinia } from 'pinia';
import Vant from 'vant';
import {
  Lazyload,
  Toast
} from 'vant';
import 'amfe-flexible';
// import 'vant/es/notify/style' //这个样式有点丑
import 'vant/lib/index.css';

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(Vant);
app.use(Lazyload);
app.use(Toast);
app.mount('#app');